import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    languages: []
}

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        updateConfigurations(state: any, action) {
            return {
                ...state,
                ...action.payload
            }
        },
        updateConfigurationItem(state: any, action) {
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        }
    }
});

export const { updateConfigurations, updateConfigurationItem } = configurationsSlice.actions;

export default configurationsSlice.reducer;