import * as localhost from "./_localhost";
import * as development from "./_development";
import * as production from "./_production";

let envConfigs: any = {
    localhost, development, production
};

let reactAppEnv: any = process.env.REACT_APP_NODE_ENV;
let reactAppNodeEnv = envConfigs[reactAppEnv]?.default;

export default {
    //configs based on REACT_APP_NODE_ENV
    ...reactAppNodeEnv,
    //common configs below

}