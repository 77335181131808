import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../Utils/localStorage";

export default function usePersistedState(key: string, defaultValue: any) {

  const [state, setState] = useState(() => {
    let value: any = getLocalStorageItem(key);
    if (value !== null) {
      return JSON.parse(value);
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    setLocalStorageItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];

}
